var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticStyle:{"border-bottom":"1px solid #EEE"}},[_c('v-col',{attrs:{"cols":12,"sm":"8","md":"5"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',{staticStyle:{"border-radius":"0%","width":"64px","height":"64px"}},[_c('v-img',{attrs:{"src":_vm.student.photo,"contain":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.student.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.student.email))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":12,"sm":"4","md":"3"}},[_c('div',{staticStyle:{"font-size":".9em"}},[_vm._v("Concern Level: "+_vm._s(_vm.student.concern === 'N' ? 'None' : (_vm.student.concern === 'M' ? 'Medium' : 'High')))]),_c('v-btn-toggle',{attrs:{"value":_vm.student.concern},on:{"change":function (concern) { return _vm.$emit('change', { concern: concern }); }}},[_c('v-btn',{attrs:{"value":'N'}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fas fa-circle")])],1),_c('v-btn',{attrs:{"value":'M'}},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("fas fa-circle")])],1),_c('v-btn',{attrs:{"value":'H'}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fas fa-circle")])],1)],1),_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialogOn = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 mb-4",attrs:{"icon":"","outlined":""}},Object.assign({}, dialogOn, tooltipOn)),[_c('v-icon',[_vm._v(_vm._s(_vm.student.comments.length > 0 ? 'fas' : 'fal')+" fa-comments")])],1)]}}],null,true)},[(_vm.student.comments.length > 0)?_c('span',[_vm._v("View/Add Comments")]):_c('span',[_vm._v("Add Comment")])])]}}]),model:{value:(_vm.newCommentDialog),callback:function ($$v) {_vm.newCommentDialog=$$v},expression:"newCommentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Comments")]),_c('v-card-text',[_c('v-textarea',{attrs:{"label":"Comment","outlined":"","rows":"2","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.saveComment.apply(null, arguments)}},model:{value:(_vm.newComment),callback:function ($$v) {_vm.newComment=$$v},expression:"newComment"}}),_c('v-btn',{attrs:{"disabled":_vm.newComment === '',"color":"success"},on:{"click":_vm.saveComment}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-comment-plus")]),_vm._v(" Add Comment ")],1)],1),(_vm.student.comments.length > 0)?_c('v-sheet',{staticStyle:{"max-height":"calc(100% - 400px)"}},[_c('v-timeline',{attrs:{"dense":""}},_vm._l((_vm.student.comments),function(ref,i){
var text = ref.text;
var name = ref.name;
var date = ref.date;
return _c('v-timeline-item',{key:i,attrs:{"color":"success","icon":"fal fa-comment","fill-dot":""}},[_c('v-card',[_c('v-card-text',[_vm._v(" "+_vm._s(text)+" "),_c('div',{staticStyle:{"font-size":".8em","opacity":".7","padding-top":"0"}},[_vm._v(_vm._s(name)+" -- "+_vm._s(_vm.stringFormatDate(date)))])])],1)],1)}),1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.newCommentDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":12,"md":"4"}},[(_vm.student.concern !== 'N')?_c('v-combobox',{staticClass:"mt-4",attrs:{"value":_vm.student.reasons,"label":'Concerns' + (_vm.student.concern === 'H' ? ' (Required)' : ''),"items":_vm.reasonOptions,"error":_vm.error,"multiple":"","chips":"","outlined":"","hide-details":""},on:{"change":function (reasons) { return _vm.$emit('change', { reasons: reasons }); }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }