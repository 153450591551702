<template>
  <v-row style="border-bottom:1px solid #EEE">
    <v-col :cols="12" sm="8" md="5">
      <v-list>
        <v-list-item>
          <v-list-item-avatar style="border-radius:0%;width:64px;height:64px">
            <v-img :src="student.photo" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ student.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ student.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col :cols="12" sm="4" md="3">
      <div style="font-size:.9em">Concern Level: {{  student.concern === 'N' ? 'None' : (student.concern === 'M' ? 'Medium' : 'High') }}</div>
      <v-btn-toggle :value="student.concern" @change="(concern) => $emit('change', { concern })">
        <v-btn :value="'N'">
          <v-icon color="success">fas fa-circle</v-icon>
        </v-btn>
        <v-btn :value="'M'">
          <v-icon color="warning">fas fa-circle</v-icon>
        </v-btn>
        <v-btn :value="'H'">
          <v-icon color="error">fas fa-circle</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-dialog v-model="newCommentDialog" width="600">
        <template v-slot:activator="{ on: dialogOn }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltipOn }">
              <v-btn v-on="{ ...dialogOn, ...tooltipOn }" icon outlined class="ml-2 mb-4">
                <v-icon>{{ student.comments.length > 0 ? 'fas' : 'fal' }} fa-comments</v-icon>
              </v-btn>
            </template>
            <span v-if="student.comments.length > 0">View/Add Comments</span>
            <span v-else>Add Comment</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-card-title>Comments</v-card-title>
          <v-card-text>
            <v-textarea v-model="newComment" label="Comment" outlined rows="2" hide-details @keydown.enter.prevent="saveComment"></v-textarea>
            <v-btn :disabled="newComment === ''" color="success" @click="saveComment">
              <v-icon left>fas fa-comment-plus</v-icon>
              Add Comment
            </v-btn>
          </v-card-text>
          <v-sheet v-if="student.comments.length > 0" style="max-height:calc(100% - 400px)">
            <v-timeline dense>
              <v-timeline-item v-for="({ text, name, date }, i) in student.comments" :key="i" color="success" icon="fal fa-comment" fill-dot>
                <v-card>
                  <v-card-text>
                    {{ text }}
                    <div style="font-size:.8em;opacity:.7;padding-top:0">{{ name }} -- {{ stringFormatDate(date) }}</div>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-sheet>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="newCommentDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col :cols="12" md="4">
      <v-combobox v-if="student.concern !== 'N'" :value="student.reasons" :label="'Concerns' + (student.concern === 'H' ? ' (Required)' : '')" :items="reasonOptions" :error="error" class="mt-4" multiple chips outlined hide-details @change="(reasons) => $emit('change', { reasons })"></v-combobox>
    </v-col>
  </v-row>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../helpers/formatters'

export default {
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const reasonOptions = computed(() => root.$store.state['cov-cares'].stoplight.concernOptions)

    const newCommentDialog = ref(false)
    const newComment = ref('')

    function saveComment () {
      emit('addComment', { text: newComment.value, user: user.value.name, date: new Date() })
      newComment.value = ''
    }
    const error = ref(false)

    watch(() => props.student.reasons, () => {
      if (props.student.reasons.length > 0) error.value = false
    })

    function validate () {
      if (props.student.concern === 'H' && props.student.reasons.length === 0) error.value = true
      else error.value = false
      return !error.value
    }

    return {
      reasonOptions,
      newCommentDialog,
      newComment,
      saveComment,
      error,
      validate,
      stringFormatDate
    }
  }
}
</script>
